import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   redirect: 'dashboard',
  // },
  {
    path: '/',
    redirect: 'login',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/master/report',
    name: 'master-report',
    component: () => import('@/views/master/MasterReport.vue'),
  },
  {
    path: '/master/report/detail',
    name: 'master-report-detail',
    component: () => import('@/views/master/MasterReportDetail.vue'),
  },
  {
    path: '/master/quotation',
    name: 'master-quotation',
    component: () => import('@/views/master/MasterQuotation.vue'),
  },
  {
    path: '/quotation',
    name: 'quotation',
    component: () => import('@/views/quotation/Quotation.vue'),
  },
  {
    path: '/quotation/detail',
    name: 'quotation-detail',
    component: () => import('@/views/quotation/QuotationDetail.vue'),
    props: route => ({ query: route.query.q })
  },
  {
    path: '/campaign',
    name: 'campaign',
    component: () => import('@/views/campaign/Campaign.vue'),
  },
  {
    path: '/campaign/choose-influencer',
    name: 'campaign-choose-influencer',
    component: () => import('@/views/campaign/ChooseInfluencer.vue'),
    props: route => ({ query: route.query.q })
  },
  {
    path: '/campaign/list-package',
    name: 'campaign-list-package',
    component: () => import('@/views/campaign/ListPackage.vue'),
    props: route => ({ query: route.query.q })
  },
  {
    path: '/campaign/cart',
    name: 'campaign-cart',
    component: () => import('@/views/campaign/Cart.vue'),
  },
  {
    path: '/campaign/payment',
    name: 'campaign-payment',
    component: () => import('@/views/campaign/Payment.vue'),
  },
  {
    path: '/campaign/payment/payment-detail',
    name: 'campaign-payment-detail',
    component: () => import('@/views/campaign/PaymentDetail.vue'),
  },
  {
    path: '/campaign/payment/payment-list',
    name: 'campaign-payment-list',
    component: () => import('@/views/campaign/PaymentList.vue'),
  },
  {
    path: '/report',
    name: 'report',
    component: () => import('@/views/report/Report.vue'),
  },
  {
    path: '/report/detail',
    name: 'report-detail',
    component: () => import('@/views/report/ReportDetail.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/profile/Profile.vue'),
  },
  {
    path: '/campaigns',
    name: 'campaigns',
    component: () => import('@/views/campaigns/Campaigns.vue'),
  },
  {
    path: '/campaigns/detail',
    name: 'campaigns-detail',
    component: () => import('@/views/campaigns/CampaignsDetail.vue'),
  },
  {
    path: '/discovery',
    name: 'discovery',
    component: () => import('@/views/discovery/Discovery.vue'),
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: () => import('@/views/Redirect.vue'),
  },
  // {
  //   path: '/typography',
  //   name: 'typography',
  //   component: () => import('@/views/typography/Typography.vue'),
  // },
  // {
  //   path: '/icons',
  //   name: 'icons',
  //   component: () => import('@/views/icons/Icons.vue'),
  // },
  // {
  //   path: '/cards',
  //   name: 'cards',
  //   component: () => import('@/views/cards/Card.vue'),
  // },
  // {
  //   path: '/simple-table',
  //   name: 'simple-table',
  //   component: () => import('@/views/simple-table/SimpleTable.vue'),
  // },
  // {
  //   path: '/form-layouts',
  //   name: 'form-layouts',
  //   component: () => import('@/views/form-layouts/FormLayouts.vue'),
  // },
  // {
  //   path: '/pages/account-settings',
  //   name: 'pages-account-settings',
  //   component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
  // },
  // {
  //   path: '/pages/login',
  //   name: 'pages-login',
  //   component: () => import('@/views/pages/Login.vue'),
  //   meta: {
  //     layout: 'blank',
  //   },
  // },
  // {
  //   path: '/pages/register',
  //   name: 'pages-register',
  //   component: () => import('@/views/pages/Register.vue'),
  //   meta: {
  //     layout: 'blank',
  //   },
  // },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
