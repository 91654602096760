<template>
  <div>
    <!-- MOBILE -->
    <v-app v-if="isMobileWidth === 1">
      <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>
      <v-app-bar
        app
        flat
        absolute
        color="white"
        elevate-on-scroll
        class="fixed"
        style="z-index: 1000; left:0px !important"
      >
        <div class="boxed-container w-full" style="max-width:500px !important;">
          <!-- HALAMAN HOME -->
          <div v-if="page === '/dashboard'">
            <div class="d-flex justify-space-between mx-7 ml-4 mr-4">
              <!-- Left Content -->
              <!-- <v-app-bar-nav-icon
                style="display: block !important;justify-content:start !important"
                class="d-block d-lg-none mr-0 pr-0 accent--text"
                @click="isDrawerOpen = !isDrawerOpen"
              ><i class="fas fa-bars" style="font-size:28px"></i></v-app-bar-nav-icon>

              <v-spacer></v-spacer> -->
              <router-link
                to="/"
                class="d-flex align-center text-decoration-none"
              >
                <v-img
                  :src="require('@/assets/images/logos/logo-primary.png')"
                  width="45px"
                  alt="logo"
                  contain
                  eager
                  class="app-logo mr-1"
                ></v-img>
                <v-slide-x-transition>
                  <p class="primary--text font-weight-bold pt-4 text-22">
                    Platform
                  </p>
                </v-slide-x-transition>
              </router-link>
              <v-spacer></v-spacer>

              <!-- Right Content -->
              <v-btn
                icon
                small
                class="ms-3 pt-8"
              >
                <v-icon class="black--text">
                  {{ icons.mdiBellOutline }}
                </v-icon>
              </v-btn>
              <!-- PROFILE -->
              <app-bar-user-menu :business_name="business_name" :pic_name="pic_name"></app-bar-user-menu>
            </div>
            <!-- <div class="ml-4 mr-4">
                <h2>Hi Erwin</h2>
            </div> -->
          </div>

          <!-- HALAMAN DETAIL -->
          <div v-else class="d-flex justify-space-between mx-7 ml-5 pt-5">
            <!-- Left Content -->
            <v-app-bar-nav-icon
              v-if="pageItem === 'Campaign Report' || pageItem === 'Master Report Detail'"
              style="display: block !important;justify-content:start !important"
              class="d-block d-lg-none mr-0 pr-0 gray--text mt-0"
              @click="$router.go(-1)"
            ><i class="fas fa-chevron-left text-16"></i></v-app-bar-nav-icon>

            <!-- <v-spacer></v-spacer> -->
            <router-link
              to="/"
              class="text-decoration-none"
            >
              <p v-if="pageItem === 'Campaign Report' || pageItem === 'Master Report Detail'" class="text-capitalize black--text pt-2 font-weight-bold"> {{ pageItem | title}}</p>
              <p v-else class="text-capitalize black--text headline pt-2 font-weight-bold"> {{ pageItem | title}}</p>
              <v-slide-x-transition>
              </v-slide-x-transition>
            </router-link>
            <!-- <v-spacer></v-spacer> -->
            <v-app-bar-nav-icon
              style="display: block !important;justify-content:end !important"
              class="d-block d-lg-none ml-0 pl-0 white--text"
              @click="$router.go(-1)"
            ><i class="fas fa-chevron-left text-24"></i></v-app-bar-nav-icon>
          </div>
        </div>
      </v-app-bar>
      <v-main style="padding-left: 0px !important;">
        <div class="app-content-container boxed-container white" style="max-width:500px;min-height:100%">
          <slot></slot>
        </div>
      </v-main>
      <v-bottom-navigation
        v-if="pageItem != 'campaign-detail'"
        color="white"
        fixed
        class="d-flex"
        horizontal
        style="bottom:-1px;box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important"
      >
        <div class="boxed-container w-full" style="max-width:500px !important;">
          <v-row no-gutters>
            <v-col
              cols="3"
              class="text-center pl-0 pr-0"
            >
              <v-card
                color="white"
                elevation="0"
                class="accent--text text-capitalize"
                height="60px"
                tile
                @click="goTo('dashboard')"
              >
                <v-card-text class="pa-0 pt-2">
                  <div v-if="menu === 'dashboard' || menu === ''" >
                    <v-icon class="mb-0 mt-0 text-14 primary--text">
                      {{ icons.mdiHome }}
                    </v-icon><br>
                    <p class="text-10  primary--text font-weight-bold">Home</p>
                  </div>
                  <div v-else>
                    <v-icon class="mb-0 mt-0 text-14">
                      {{ icons.mdiHomeOutline }}
                    </v-icon><br>
                    <p class="text-10">Home</p>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="3"
              class="text-center pl-0 pr-0"
              v-if="role == 'admin'"
            >
              <v-card
                color="white"
                elevation="0"
                class="accent--text text-capitalize text-10"
                height="60px"
                tile
                @click="goTo('master-report')"
              >
                <v-card-text class="pa-0 pt-2">
                  <div v-if="menu === 'master'" >
                    <v-icon class="mb-0 mt-0 text-18 primary--text">
                      {{ icons.mdiDatabaseOutline  }}
                    </v-icon><br>
                    <p class="text-10  primary--text">Master</p>
                  </div>
                  <div v-else>
                    <v-icon class="mb-0 mt-0 text-18">
                      {{ icons.mdiDatabaseOutline  }}
                    </v-icon><br>
                    <p class="text-10">Master</p>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              v-else
              cols="3"
              class="text-center pl-0 pr-0"
            >
              <v-card
                color="white"
                elevation="0"
                class="accent--text text-capitalize text-10"
                height="60px"
                tile
                @click="redirect()"
              >
                <v-card-text class="pa-0 pt-2">
                  <div v-if="menu === 'package'" >
                    <v-icon class="mb-0 mt-0 text-18 primary--text">
                      {{ icons.mdiMagnify  }}
                    </v-icon><br>
                    <p class="text-10  primary--text">Discover</p>
                  </div>
                  <div v-else>
                    <v-icon class="mb-0 mt-0 text-18">
                      {{ icons.mdiMagnify  }}
                    </v-icon><br>
                    <p class="text-10">Discover</p>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="3"
              class="text-center pl-0 pr-0"
            >
              <v-card
                color="white"
                elevation="0"
                class="accent--text text-capitalize text-10"
                height="60px"
                tile
                @click="goTo('campaigns')"
              >
                <v-card-text class="pa-0 pt-2">
                  <div v-if="menu === 'campaigns'" >
                    <v-icon class="mb-0 mt-0 text-18 primary--text">
                      {{ icons.mdiBullhorn }}
                    </v-icon><br>
                    <p class="text-10 primary--text font-weight-bold">Campaigns</p>
                  </div>
                  <div v-else>
                    <v-icon class="mb-0 mt-0 text-18">
                    {{ icons.mdiBullhornOutline }}
                  </v-icon><br>
                  <p class="text-10">Campaigns</p>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="3"
              class="text-center pl-0 pr-0"
            >
              <v-card
                color="white"
                elevation="0"
                class="accent--text text-capitalize text-10"
                height="60px"
                tile
                @click="goTo('profile')"
              >
                <v-card-text class="pa-0 pt-2">
                  <div v-if="menu === 'profile'" >
                    <v-icon class="mb-0 mt-0 text-18 primary--text">
                      {{ icons.mdiAccount  }}
                    </v-icon><br>
                    <p class="text-10 primary--text font-weight-bold">Profile</p>
                  </div>
                  <div v-else >
                    <v-icon class="mb-0 mt-0 text-18">
                      {{ icons.mdiAccountOutline  }}
                    </v-icon><br>
                    <p class="text-10">Profile</p>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-bottom-navigation>
    </v-app>
    <!-- DESKTOP -->
    <v-app v-else>
      <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>
      <v-app-bar
        app
        flat
        absolute
        color="primary"
        elevate-on-scroll
        class="fixed"
        style="z-index: 1000;"
      >
        <div class="boxed-container w-full">
          <div class="d-flex align-center mx-6">
            <!-- Left Content -->
            <v-app-bar-nav-icon
              class="d-block d-lg-none me-2 white--text"
              @click="isDrawerOpen = !isDrawerOpen"
            ></v-app-bar-nav-icon>
            <theme-switcher></theme-switcher>
            <!-- <v-text-field
              rounded
              dense
              outlined
              :prepend-inner-icon="icons.mdiMagnify"
              class="app-bar-search flex-grow-0"
              hide-details
            ></v-text-field> -->

            <v-spacer></v-spacer>

            <!-- Right Content -->
            <!-- <a
              href="https://github.com/themeselection/materio-vuetify-vuejs-admin-template-free"
              target="_blank"
              rel="nofollow"
            >
              <v-icon class="ms-6 me-4">
                {{ icons.mdiGithub }}
              </v-icon>
            </a> -->
            <!-- <v-btn
              color="primary"
              class="mx-2"
              fab
              larga
              @click="cart"
            >
              <i class="fas fa-shopping-cart white--text" style="font-size:20px"></i>
            </v-btn> -->
            <v-btn
              icon
              small
              class="ms-3"
            >
              <v-icon class="white--text">
                {{ icons.mdiBellOutline }}
              </v-icon>
            </v-btn>
            <app-bar-user-menu :business_name="business_name" :pic_name="pic_name"></app-bar-user-menu>
          </div>
        </div>
      </v-app-bar>

      <v-main>
        <div class="app-content-container boxed-container pa-6">
          <slot></slot>
        </div>
      </v-main>

      <v-footer
        v-if="isMobileWidth != 1"
        app
        inset
        color="transparent"
        absolute
        height="56"
        class="px-0"
      >
        <div class="boxed-container w-full">
          <div class="mx-6 d-flex justify-space-between">
            <span>
              VDC Platform 1.2.0
             <!-- PT Sosial Kreatif Media -->
            </span>
            <span class="d-sm-inline d-none">
              <!-- VDC Platform -->
              &copy; 2024 Vice Digital Creative | All Rights Reserved
            </span>
          </div>
        </div>
      </v-footer>
    </v-app>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify,
        mdiBellOutline,
        mdiGithub,
        mdiHomeOutline,
        mdiLightningBoltOutline,
        mdiBullhornOutline,
        mdiCardAccountDetailsOutline,
        mdiDotsGrid,
        mdiClipboardAccountOutline,
        mdiBriefcaseAccountOutline,
        mdiHome,
        mdiBriefcaseAccount,
        mdiLightningBolt,
        mdiBullhorn,
        mdiAccount,
        mdiAccountOutline,
        mdiDatabaseOutline   } from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'

import VueJwtDecode from 'vue-jwt-decode'

export default {
  name: 'Platform',
  metaInfo: {
    title: 'VDC Platform',
    titleTemplate: '%s | VDC Platform'
  },
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
  },
  setup() {
    const isDrawerOpen = ref(null)

    return {
      isDrawerOpen,
      business_name:'',
      pic_name:'',
      isMobileWidth:0,
      menu:'',
      page:'dashboard',
      pageItem:'',
      role:'',

      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
        mdiHomeOutline,
        mdiLightningBoltOutline,
        mdiBullhornOutline,
        mdiCardAccountDetailsOutline,
        mdiDotsGrid,
        mdiClipboardAccountOutline,
        mdiBriefcaseAccountOutline,
        mdiHome,
        mdiBriefcaseAccount,
        mdiLightningBolt,
        mdiBullhorn,
        mdiAccount,
        mdiAccountOutline,
        mdiDatabaseOutline
      },
    }
  },
  mounted(){
    this.getTitle()
    this.page = this.$route.path
    // console.log(this.$route)
    if(this.$route.name === 'category-all'){
      this.pageItem = 'Kategori'
    } else if(this.$route.name === 'nano-influencer'){
      this.pageItem = 'Nano Influencer'
    } else if(this.$route.name === 'micro-influencer'){
      this.pageItem = 'Micro Influencer'
    } else if(this.$route.name === 'premium-influencer'){
      this.pageItem = 'Premium Influencer'
    } else if(this.$route.name === 'rekomendasi-influencer'){
      this.menu = 'rekomendasi'
      this.pageItem = 'Rekomendasi Influencer'
    } else if(this.$route.name === 'package-all'){
      this.menu = 'package'
      this.pageItem = 'Paket Influencer'
    } else if(this.$route.name === 'campaign'){
      this.menu = 'campaign'
      this.pageItem = 'Campaign'
    } else if(this.$route.name === 'campaigns-detail'){
      this.menu = 'campaigns'
      this.pageItem = 'Campaign Report'
    } else if(this.$route.name === 'package-detail'){
      this.pageItem = 'Paket Detail'
    } else if(this.$route.name === 'influencer-detail'){
      this.pageItem = 'Detail Influencer'
    } else if(this.$route.name === 'category-detail'){
      this.pageItem = this.$route.query.item
    } else if(this.$route.name === 'faq'){
      this.pageItem = 'Frequently Asked Questions'
    } else if(this.$route.name === 'master-report'){
      this.menu = 'master'
      this.pageItem = 'Master Report'
    } else if(this.$route.name === 'master-report-detail'){
      this.menu = 'master'
      this.pageItem = 'Master Report Detail'
    } else {
      this.pageItem = this.$route.name
      this.menu = this.$route.name
    }

    if(!localStorage.getItem('token')){
      this.$router.push({name:'login'});
    } else {
      let token = VueJwtDecode.decode(localStorage.getItem('token'))
      this.role = token.role
      let user = {
        'user_id': token.id,
        'business_name': token.business_name,
        'pic_name': token.pic_name,
        'role': token.role
      }
      localStorage.setItem('user', JSON.stringify(user));
      let getUser = JSON.parse(localStorage.getItem('user'));
      this.business_name = getUser.business_name;
      this.pic_name = getUser.pic_name;
    }

    this.isMobile()
    window.addEventListener('resize', () => {
      this.isMobile()
    })
  },
  methods:{
    cart(){
      this.$router.push({name:'campaign-cart'});
    },
    getTitle(){
      if(this.$route.fullPath.includes('/category/') || this.page === '/dashboard'){
        this.menu = 'home'
      }
    },
    goTo(url){
      this.$router.push({name:url});
    },
    redirect(){
      // window.location.href = 'https://app.vicedigitalcreative.com/'
      window.open('https://app.vicedigitalcreative.com/','_blank')
    },
    isMobile() {
      let size
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        size = 1
      } else {
        size = 0
      }

      if(window.innerWidth < 778){
        this.isMobileWidth = 1
      } else if(size === 1){
        this.isMobileWidth =1
      }else {
        this.isMobileWidth = 0
      }
    },
  },
  filters:{
    title(val){
      let newVal = val.replace("&", " & ");
      if(val === 'category-all'){
        return 'Kategori'
      } else {
        return newVal.replace("-", " ");
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
